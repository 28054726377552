<template>
  <div class="flag" :style="{ height: `${size}px` }">
    <div class="flag-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="height"
        :aria-labelledby="titleId"
        viewBox="0 0 28 20"
        role="img"
        fill="none"
      >
        <!--role="presentation"-->
        <title :id="titleId" lang="en">{{ name }}</title>
        <g>
          <slot />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useComponentId } from '@en-ui/composables/useComponent'
export default {
  name: 'AppFlag',
  props: {
    name: {
      type: String,
      default: 'Icon',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
  },
  setup(props) {
    const titleId = useComponentId('title')

    // Computed Height
    const aspectRatio = 5 / 7
    const height = computed(() => {
      return props.size * aspectRatio
    })

    return {
      height,
      titleId,
    }
  },
}
</script>

<style scoped>
.flag {
  @apply inline-flex items-center;
}
.flag-wrapper {
  @apply inline-flex;
  @apply relative;
  @apply rounded-sm;
  &::after {
    content: ' ';
    @apply border-gray-light rounded-sm box-border border;
    @apply absolute top-0 right-0 bottom-0 left-0;
  }
}
svg {
  @apply align-baseline;
  @apply rounded-sm;
}
</style>
