<template>
  <XeInputDropdown
    v-model="inputValue"
    :dropdownLabel="dropdownLabel"
    optionLabel="name"
    optionValue="code"
    placeholder="Select country"
    id="companycountry"
    :disabled="disabled"
    :options="countries"
    :validationErrorMessage="validationErrorMessage"
    @input="onCountrySet($event)"
  >
    <template #custom-value-placeholder="{ selectedValue, selectedLabel }">
      <div class="country-item">
        <AppFlag class="country-flag">
          <Component :is="getFlag(selectedValue)"></Component>
        </AppFlag>
        <span class="country-name-after-icon">{{ selectedLabel }}</span>
      </div>
    </template>

    <template #custom-option="{ option }">
      <div class="country-item">
        <AppFlag class="country-flag">
          <Component :is="getFlag(option.code)"></Component>
        </AppFlag>
        <span class="country-name-after-icon">{{ option.name }}</span>
      </div>
    </template>
  </XeInputDropdown>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppFlag from '@en-ui/components/AppFlag/AppFlag'
import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'
import * as EuronetFlags from '@moneytransfer.ui/euronet-flags'

export default {
  name: 'XeInputCountry',
  components: {
    AppIcon,
    AppFlag,
    XeInputDropdown,
  },
  emit: ['input'],
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownLabel: {
      type: String,
      default: ''
    },
    validationErrorMessage: {
      type: String,
      default: '',
    },
    countries: {
      type: Array,
      default: null,
      required: true,
    }
  },
  setup($props, { emit }) {
    const inputValue = ref('')

    const getFlag = (flagCode) => {
      let flag = null

      if (flagCode) flag = 'Flag' + flagCode.toUpperCase()
      if (flag === 'FlagKV') flag = 'FlagCV'

      return EuronetFlags[flag] || null
    }

    const onCountrySet = async (country) => {
      emit('input', country)
    }

    // When component has a value, set input values
    const updateInputValues = () => {
      inputValue.value = $props.value
    }

    // Update input values if component has initial value
    if ($props.value.length > 0) {
      updateInputValues()
    }
    // Update input values if props.value updated not from component
    watch(() => $props.value, updateInputValues)

    return {
      inputValue,
      getFlag,
      onCountrySet,
    }
  }
}
</script>

<style lang="postcss" scoped>
.country-item {
  @apply flex flex-row items-center;

  .country-name-after-icon {
    @apply ml-2;
  }
}
</style>